import React from "react";
import Coverfb from "images/facebook-f.svg";
import Coveryb from "images/youtube1.svg";
import Coverins from "images/instagram-alt.svg";

const BlockIcon = () =>{
    return(
        <div className="icon__footer">
            <ul>
                <li>
                    <a href="https://www.facebook.com/alphaland.com.vn" target="_blank">
                        <img src={Coverfb}/>
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/channel/UCvwWr8yrdepDKIiFlAUYJKw" target="_blank">
                        <img src={Coveryb}/>
                    </a>
                </li>
                <li>
                    <a href="">
                        <img src={Coverins}/>
                    </a>
                </li>
            </ul>
        </div>
    )
}
export default BlockIcon