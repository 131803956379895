import React,{useEffect,useState } from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import "../styles/page/contacts.css";
import { Formik, Field, Form } from "formik";
import iconclose from "images/icon/times.svg";
import Seo from "../components/seo";
import { Link } from "gatsby";
import ImageContact from "images/icon/arrow-right.svg";
import BlockIcon from "../components/homepage/block-icon";
import iconlogo from "images/alphaland_logo.svg";
import { GatsbyImage} from "gatsby-plugin-image";
const contact = () => {
  const { strapiContact } = useStaticQuery(graphql`
    query {
      strapiContact {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80
                webpOptions: {quality: 80}
                placeholder: BLURRED)
            }
          }
        }
        title
      }
    }
  `);
  const [popupForm, setPopupForm] = useState(false)
  const [contactFrom, setContactFrom] = useState("Liên Hệ");
  const [nameFrom, setNameFrom] = useState("");
  const [phoneFrom, setPhoneFrom] = useState("");
  const [emailFrom, setEmailFrom] = useState("");
  const [contentFrom, setContentFrom] = useState("");
  return (
    <Layout>
      <Seo seo={{ metaTitle: "Liên Hệ" }} />
      
      <div>
        <div className="about">
          
            <div >
                  <GatsbyImage image={strapiContact.image.localFile.childImageSharp.gatsbyImageData} alt="image" className="project__baner"/>
              <div className="container">
                <div className="baner__slider Home__siteTitle">
                  <div>
                    <Link to="/" title="Trang-chu" className="baner__title">
                      Trang Chủ
                    </Link>{" "}
                    <span className="baner__title">/ {strapiContact.title}</span>
                  </div>
                  <h2 className="baner__desc">{strapiContact.title}</h2>
                </div>
              </div>
            </div>
        </div>
        <div className="container">
          <div className="contacts">
            <div className="contacts__phone">
              <span></span>
              <ul>
                <li>
                  <p>Trụ Sở Chính</p>
                  <h3>
                    111 Trương Văn Bang, Phường Thạnh Mỹ Lợi, Thành phố Thủ Đức,
                    Hồ Chí Minh
                  </h3>
                </li>
                <li>
                  <p>Thời gian làm việc</p>
                  <h3>Thứ 2 - Thứ 7 : 8:30 - 17:30 </h3>
                </li>
                <li>
                  <p>Thông tin liên hệ</p>
                  <h3>
                  <a href="tel:0932657979">0932 657 979</a><br></br>
                    <a href="mailto:info@alphaland.com.vn">info@alphaland.com.vn</a>
                  </h3>
                </li>
              </ul>
            </div>
            {popupForm && 
                <>
                  <div onClick={() =>{setPopupForm(false)}} className="popup__fix"></div>
                  <div className="popup">
                      <div className="close__img" onClick={() =>{setPopupAlter(false)}}><img src={iconclose} className="icon__close"/></div>
                      <img className="image__logo-close" src={iconlogo}/>
                      <div className="home__siteTitle">
                      <div className="popup__recruitmentBlock">
                      <div className="pupop__row title__recruit">
                          <h2 className="mgbt pdt">Cảm ơn anh/chị đã để lại thông tin.</h2>
                          <div>
                            Chuyên viên tư vấn sẽ liên hệ hỗ trợ anh chị trong thời gian sớm nhất!
                          </div>
                      </div>
                      </div>
                      </div>
                  </div>
                </>
            }
            <div className="contacts__now home__siteTitle">
              <h2>Liên hệ ngay với chúng tôi</h2>
              <div>
                <Formik
                  initialValues={{
                    nameFrom,
                    phoneFrom,
                    emailFrom,
                    contentFrom,
                  }}
                    onSubmit={async (values) => {
                    let data = {
                        'entry.545121623': contactFrom,
                        'entry.678634837': nameFrom,
                        'entry.344178928': phoneFrom,
                        'entry.529291201': emailFrom,
                        'entry.730657229': contentFrom,
                        
                    }
                    await new Promise((resolve) => setTimeout(resolve, 500));
                        let queryString = new URLSearchParams(data);
                        queryString = queryString.toString();
                        console.log(queryString)
                        let xhr = new XMLHttpRequest();
                        xhr.open('POST', 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfiLFI4iyP8LE0ePxSULb0mUTg0d4LwDROiKjmZelFj2qUclw/formResponse', true);
                        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                        xhr.send(queryString)
                        setNameFrom("")
                        setPhoneFrom("")
                        setEmailFrom("")
                        setContentFrom("")
                        setPopupForm(true)
                    }}
                >
                  <Form className="contact__Form">
                    <p>Họ và tên * </p>
                    <Field
                      className="form-Control"
                      id="name"
                      name="name"
                      placeholder="Vui lòng nhập họ và tên của bạn"
                      value={nameFrom} onChange={(e) => setNameFrom(e.target.value)} required
                    />
                    <div className="Form_flex">
                      <div>
                        <p>Số điện thoại *</p>
                        <Field
                          className="form-Control"
                          id="phone"
                          name="phone"
                          placeholder="0837xxxxxx"
                          value={phoneFrom} onChange={(e) => setPhoneFrom(e.target.value)} required
                        />
                      </div>
                      <div>
                        <p>Địa chỉ Email *</p>
                        <Field
                          className="form-Control"
                          id="email"
                          name="email"
                          placeholder="Địa chỉ Email liên hệ"
                          value={emailFrom} onChange={(e) => setEmailFrom(e.target.value)} required
                        />
                      </div>
                    </div>
                    <div className="textarea-text">
                      <p>Nội dung *</p>
                    </div>
                    <Field
                      className="textarea-Control"
                      as="textarea"
                      id="content"
                      name="content"
                      placeholder="Nội dung"
                      value={contentFrom} onChange={(e) => setContentFrom(e.target.value)} required
                    />
                    <button className="button">
                      Gửi thông tin
                      <img src={ImageContact} className="icon_button" />
                    </button>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="home__map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.412218258644!2d106.75026385109992!3d10.779706662041388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317525dc0c470f15%3A0xd38c0faad90f24b3!2zMTExIFRyxrDGoW5nIFbEg24gQmFuZywgUGjGsOG7nW5nIFRo4bqhbmggTeG7uSBM4bujaSwgUXXhuq1uIDIsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCwgVmlldG5hbQ!5e0!3m2!1sen!2s!4v1652850158411!5m2!1sen!2s" width="100%" height="450"   loading="lazy" ></iframe>
          </div>
        </div>
      </div>
      <BlockIcon/>
    </Layout>
  );
};

export default contact;
